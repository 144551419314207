import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import {
    useNodesState,
    useEdgesState
} from 'reactflow';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { defaultEdges, defaultNodes } from './DefaultNodesAndEdges';
import { useLocation } from 'react-router-dom';
import JourneyPostWrapper from './JourneyPostWrapper';
import '../../../Styles/Journeys/JourneyPanel.css';
import EditMileStone from './EditMileStone';
import AddPost from '../AddPost';
import JourneyPanel from './JourneyPanel';



//es lint exhaustive dependencies are disabled throughout this file.
//trying to fix them resulted in infitine re-renders hence, I have elected to just trust the dependencies I intentionally added 
function JourneyTab(props) {
    const isMounted = useRef();

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    const location = useLocation();
    const [nodes, setNodes, onNodesChange] = useNodesState(defaultNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(defaultEdges);
    const [noRestore, setNoRestore] = useState(false); //blocks restoring journey when deleting edge
    const [newEdgeStartNode, setNewEdgeStartNode] = useState('');
    const [showAddPost, setShowAddPost] = useState(false);
    const [nodeHasEdges, setNodeHasEdges] = useState(false);
    const [selectedEdge, setSelectedEdge] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const [hasPosts, setHasPosts] = useState(false);
    const isLoading = props.isLoading;
    const [triggerPost, setTriggerPost] = useState(false);

    const [addingPost, setAddingPost] = useState(false);
    const [addingPostFromGraph, setAddingPostFromGraph] = useState(false)
    // store information from New Post when adding a post on the Journey page

    const [showCongrats, setShowCongrats] = useState(false);
    const [journeyHeight, setJourneyHeight] = useState(0);

    useEffect(() => {
        const screenHeight = window.innerHeight;
        const screenWidth = window.innerWidth;
        const offset = showAddPost ? (!hasPosts && props.viewerIsEditor ? 160 : 100) : 0;
        setJourneyHeight(screenHeight - 280 - offset);
    }, [window.innerHeight, window.innerWidth, props.viewerIsEditor, hasPosts, showAddPost])


    //journey should stop loading anyway from the onrestore function but this is just an extra check
    //the hope is the user can't edit the default journey whilst a large journey takes time to load
    useEffect(() => {
        if (props.journeyLoaded || location.hash !== "" || props.journeyNotFound) {
            props.isLoading(false);
        }
        else {
            props.isLoading(true);
        }
    }, [props.journeyLoaded, props, props.journeyNotFound, location.hash])

    useEffect(() => {
        //set the post pebbles to be clickable or not during adding post selection
        if (addingPost) {
            setEdges((nds) =>
                nds.map((edge) => {
                    return {
                        ...edge,
                        data: {
                            ...edge.data,
                            clickable: false,
                        },
                    };
                }))
        }
    }, [addingPost])

    var timeoutID;

    const onPost = () => {
        //Posts when the user uses the bottom of the journey page
        //We temporarily show the new post modal for 2 seconds to display the congrats message
        if (isMounted.current) {
            props.onSave()
            setNoRestore(false)
            setShowCongrats(true)
            window.location.href = "#newPost"
            setTriggerPost(true);
            timeoutID = setTimeout(() => {
                window.location.href = "#!"
                setShowCongrats(false);
                setAddingPost(false);
            }, 2000)
        }
    }

    useEffect(() => {
        return () => clearTimeout(timeoutID)
    }, [])

    const changeMileStoneNodeType = useCallback(
        (postID, nodeID = '', error = false) => {
            setNoRestore(true);
            setNodes((nds) =>
                nds.map((node) => {
                    if (node.id === (selectedNode ? selectedNode.id : nodeID)) {
                        if (error) {
                            const type = "milestoneNode"
                            return {
                                ...node,
                                type,
                            };
                        } else {
                            const type = "milestoneNodeWithPost";
                            const post = postID;
                            return {
                                ...node,
                                type,
                                data: {
                                    ...node.data,
                                    post,
                                },
                            };
                        }
                    } else {
                        return node;
                    }
                })
            );
        }, [selectedNode, setNoRestore, setNodes]
    )

    const addPostFromGraph = () => {

        setAddingPostFromGraph(true)
        addPost()
    }


    const addPost = useCallback(() => {
        setNoRestore(false);
        props.onSave();
        window.location.href = "#newPost";
    }, [props.onSave, setNoRestore])

    const refreshJourney = useCallback((itemID, postID) => {
        var RFInstance = JSON.parse(props.tempStorage);
        RFInstance.edges.forEach((x) => {
            if (x.id === itemID) {
                x.data.posts.push(postID)
            }
        })
        RFInstance.nodes.forEach((x) => {
            if (x.id === itemID) {
                changeMileStoneNodeType(postID, itemID)
            }
        })
        props.setTempStorage(JSON.stringify(RFInstance))
        setSelectedEdge(null);
        setSelectedNode(null);
        setAddingPostFromGraph(false);
        setEdges((nds) =>
            nds.map((edge) => {
                return {
                    ...edge,
                    data: {
                        ...edge.data,
                        clickable: true,
                    },
                };
            }))
    }, [props.tempStorage])

    return (
        <div>
            <div className="select-black-background" style={{ display: addingPost ? "block" : "none" }}></div>
            <div className="journey-tab" style={{ display: "block" }}>

                <JourneyPostWrapper isLoading={isLoading}></JourneyPostWrapper>
                <EditMileStone
                    setAddingPostFromGraph={setAddingPostFromGraph}
                    viewerIsEditor={props.viewerIsEditor}
                    node={selectedNode}
                    onSave={props.onSave}
                    unsavedChanges={props.setUnsavedChanges}
                    nodeHasEdges={nodeHasEdges}
                    setNoRestore={setNoRestore}
                    setNodes={setNodes}
                >
                </EditMileStone>
                <JourneyPanel
                    unsavedChanges={props.unsavedChanges}
                    setUnsavedChanges={props.setUnsavedChanges}
                    addPostFromGraph={addPostFromGraph}
                    onSave={props.onSave}
                    viewerIsEditor={props.viewerIsEditor}
                    addingPost={addingPost}
                    journeyHeight={journeyHeight}
                    saveError={props.saveError}
                    setSaveError={props.setSaveError}
                    numFollowers={props.numFollowers}
                    tempStorage={props.tempStorage}
                    selectedEdge={selectedEdge}
                    setSelectedNode={setSelectedNode}
                    setSelectedEdge={setSelectedEdge}
                    setNodeHasEdges={setNodeHasEdges}
                    setRfInstance={props.setRfInstance}
                    onPost={onPost}
                    setHasPosts={setHasPosts}
                    hasPosts={hasPosts}
                    setNewEdgeStartNode={setNewEdgeStartNode}
                    noRestore={noRestore}
                    setNoRestore={setNoRestore}
                    setAddingPost={setAddingPost}
                    setNodes={setNodes}
                    edges={edges}
                    nodes={nodes}
                    setEdges={setEdges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange} />
                {showAddPost ? <div>
                    <div className="u-center" style={{ height: "40px" }}>
                        <span className="fa fa-chevron-down" onClick={() => setShowAddPost(false)} style={{ fontSize: "x-large", margin: "5px", cursor: "pointer" }}></span>
                    </div>
                    {props.viewerIsEditor ?
                        <div>
                            <AddPost
                                selectedNode={selectedNode}
                                setTriggerPost={setTriggerPost}
                                refreshJourney={refreshJourney}
                                setSelectedEdge={setSelectedEdge}
                                setSelectedNode={setSelectedNode}
                                setAddingPostFromGraph={setAddingPostFromGraph}
                                setAddingPost={setAddingPost}
                                addingPostFromGraph={addingPostFromGraph}
                                selectedEdge={selectedEdge}
                                journeyID={props.journeyID}
                                journeyTitle={props.title}
                                showCongrats={showCongrats}
                                triggerPost={triggerPost} />
                        </div> : ""}
                    {!hasPosts && props.viewerIsEditor ?
                        <div className="card__action-bar u-center" style={{ height: "60px" }}>
                            <button className="outline  btn-small hover-grow AI-button" onClick={() => props.getNewTemplateJourney()}>Regenerate journey template using AI <i className="fas fa-magic fa-lg"></i></button>
                        </div>
                        : ""}
                </div> :
                    <div className="u-center">
                        <span className="fa fa-chevron-up" onClick={() => setShowAddPost(true)} style={{ fontSize: "x-large", margin: "5px", cursor: "pointer" }}></span>
                    </div>}
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps, actions)(JourneyTab);